//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { validUsername } from '@/utils/validate';
import LangSelect from '@/components/LangSelect';
import request from '@/utils/request';
export default {
  name: 'ResetPass',
  components: {
    LangSelect: LangSelect
  },
  data: function data() {
    return {
      resetForm: {
        password1: null,
        password2: null
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      code: null,
      buttonDisabled: false
    };
  },
  watch: {},
  created: function created() {
    this.code = this.$route.query.code;
    console.log(this.$route);
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  methods: {
    checkCapslock: function checkCapslock() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        shiftKey = _ref.shiftKey,
        key = _ref.key;
      if (key && key.length === 1) {
        if (shiftKey && key >= 'a' && key <= 'z' || !shiftKey && key >= 'A' && key <= 'Z') {
          this.capsTooltip = true;
        } else {
          this.capsTooltip = false;
        }
      }
      if (key === 'CapsLock' && this.capsTooltip === true) {
        this.capsTooltip = false;
      }
    },
    showPwd: function showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
    },
    handleResetPass: function handleResetPass() {
      var _this = this;
      var password1 = this.resetForm.password1;
      var password2 = this.resetForm.password2;
      if (isNull(password1)) {
        this.$message.error('请输入密码');
        return;
      }
      if (password1 != password2) {
        this.$message.error('2次输入密码不一致');
        return;
      }
      if (password1.length < 6) {
        this.$message.error('密码长度不能低于6位');
        return;
      }
      request({
        url: 'user/reset-password',
        method: 'post',
        data: {
          password: password1,
          code: this.code
        }
      }).then(function (response) {
        if (response.data.code === '0') {
          _this.$message.success(_this.$t(response.data.message));
        } else {
          _this.$message.error(response.data.message);
        }
      });
    }
  }
};